@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
html, body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  touch-action: none;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #143366;
  font-size: 1.2rem;
  line-height: 1.4em;

  overflow: hidden;
  height: 100vh;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.intro_icon {
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}
